<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Pixels pendentes de criação - DV360</h1>
        <!--end::Title-->
      </div>
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <template v-if="loader && invoices.length === 0">
          <div class="py-5">
            <Loader size="big" />
          </div>
        </template>
        <template v-else>
          <!--begin::Table-->
          <table class="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
            <!--begin::Table head-->
            <thead>
              <!--begin::Table row-->
              <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th class="min-w-100px">Método de pagamento</th>
                <th class="min-w-100px">Valor</th>
                <th class="min-w-70px">Status</th>
                <th class="min-w-100px">Ações</th>
              </tr>
              <!--end::Table row-->
            </thead>
            <!--end::Table head-->
            <!--begin::Table body-->
            <tbody class="fw-semibold text-gray-600">
              <!--begin::Table row-->
              <tr v-for="(invoice, index) in invoices" :key="index">
                <td>
                  <span>{{ invoice.payment_method | formatPaymentMethod }}</span>
                </td>
                <td class=" pe-0">
                  <span>{{ invoice.amount / 100 | formatMoney(invoice.order.advertiser.currency_type) }}</span>
                </td>
                <td class=" pe-0">
                  <span>{{ invoice.payment_status | formatPaymentStatus }}</span>
                </td>
                <td>
                  <Button
                    class="btn-success btn-sm"
                    v-b-tooltip.hover title="Marcar como pago"
                    v-if="invoice.payment_status === 'WAITING_PAYMENT'"
                    :loading="loaderPayment.includes(invoice.id)"
                    @click="submitMarkAsPaid(invoice.id)"
                  >
                    <i class="fa fa-check p-0"></i>
                  </Button>
                </td>
              </tr>
              <!--end::Table row-->
            </tbody>
            <!--end::Table body-->
          </table>
          <!--end::Table-->
          <div class="text-center py-5">
            <Button
              class="btn-primary"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              Carregar mais
            </Button>
          </div>
        </template>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import InvoicesService from '@/modules/invoices/services/invoices-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  components: {
    Button,
    Loader,
    MainLayout
  },
  metaInfo () {
    return {
      title: 'Pagamentos pendentes'
    }
  },
  data () {
    return {
      isLoadMoreVisible: false,
      loader: false,
      loaderPayment: [],
      page: 1,
      invoices: []
    }
  },
  created () {
    this.getInvoices()
  },
  methods: {
    /**
     * Get invoices
     */
    async getInvoices () {
      this.loader = true
      try {
        const invoices = await InvoicesService.getInvoices({
          paymentStatus: ['WAITING_PAYMENT', 'PENDING', 'PROCESSING'],
          page: this.page
        })
        this.invoices = [...this.invoices, ...invoices.data]
        this.isLoadMoreVisible = invoices.meta.current_page !== invoices.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getInvoices()
    },
    /**
     * Submit mark as paid
     */
    async submitMarkAsPaid (id) {
      let index = this.loaderPayment.findIndex(item => {
        return item === id
      })
      if (index < 0) this.loaderPayment.push(id)
      try {
        await InvoicesService.updateInvoicePaymentStatus(id, {
          status: 'PAID'
        })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      index = this.loaderPayment.findIndex(item => {
        return item === id
      })
      this.loaderPayment.splice(index, 1)
    },
    reset () {
      this.page = 1
      this.invoices = []
      this.getInvoices()
    }
  }
}
</script>
